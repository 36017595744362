const camembert= () => {

const pieChart = (data) => {
    const ctx = document.getElementById('myChart');
    const myChart = new Chart(ctx, {
        type: 'pie',
        data: {
            labels: data.map(function(x) {
                return x.caption
            }),
            datasets: [{
                label: '',
                data: data.map(function(x) {
                    return x.count
                }),
                backgroundColor: [
                    'rgba(255, 99, 132, 0.7)',
                    'rgba(54, 162, 235, 0.7)',
                    'rgba(255, 206, 86, 0.7)',
                    'rgba(75, 192, 192, 0.7)',
                    'rgba(153, 102, 255, 0.7)',
                    'rgba(255, 159, 64, 0.7)'
                ],
                borderColor: [
                    'rgba(255, 99, 132, 1)',
                    'rgba(54, 162, 235, 1)',
                    'rgba(255, 206, 86, 1)',
                    'rgba(75, 192, 192, 1)',
                    'rgba(153, 102, 255, 1)',
                    'rgba(255, 159, 64, 1)'
                ],
                borderWidth: 1,
            }]
        },
        options: {
            scales: {
                y: {
                    beginAtZero: true,
                    display: false
                },
                x: {
                    display: false
                }
            },
            plugins: {
                legend: {
                    align: 'start',

                }
            }
        }
    });
}

const fetchstat = (userId) => {
    let userData = []

    fetch(`/tools/stat`)

        .then((response) => response.json())
        .then((data) => {
            data.forEach((result) => {
                if (result.userId === userId && userData.length <= 4) {
                    userData.push(result)
                    userData.sort((c1, c2) => (c1.count < c2.count) ? 1 : (c1.count > c2.count) ? -1 : 0);
                }
            })
            pieChart(userData)
        })
}

console.log('wesh');


const current_user = document.getElementById("current-user").innerText;
const cu = Number(current_user)
console.log(cu);


fetchstat(cu)


}


export { camembert };