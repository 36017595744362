const filter = document.querySelector('#ages');
// const panier = document.querySelectorAll('#panier');

const insertages = (data) => {
    data.Search.forEach((age) => {
        const ageTag = `
        <li>
            <p>${age.caption}</p>
        </li>`;
        filter.insertAdjacentHTML('beforeend', infos);
    });
};

// fetch data api

const fetchAges = () => {

fetch(`/tools/age`)


    .then(response => response.json())
    .then((data) => {
        data.forEach((age) => {
            const infos = `
          <li class="list-inline-item">
            <input type="checkbox" class=mybox-age data-id="${age.id}" data-caption="${age.caption}" value="box1"></input> ${age.caption}
            </li>`;
            ages.insertAdjacentHTML("beforeend", infos);
        });
        fetchAge();
    });
};


const addToBasket = (dataAgeption, id) => {  
    const panier = document.getElementById('panier');
    const infos = `<li class="list-inline-item" id="info-${id}" style="display: inline-block;">
    <i class="fas fa-baby" style="vertiagel-align: middle;"></i>
    <p style="display: inline-block; vertiagel-align: middle; margin: 0 0 0 5px;">${dataAgeption}</p>
  </li>`;
    panier.insertAdjacentHTML("beforeend", infos);
};

const fetchAge = () => {

const boxs = document.querySelectorAll('.mybox-age');
boxs.forEach((box) =>{
    box.addEventListener('click', (event) => {
        if (box.checked) {
            addToBasket(box.dataset.caption, box.dataset.id)
            download2();
            localStorage.selectedIds = state.dataset.ids
        } else {
            const id = box.dataset.id
            document.querySelector(`#info-${id}`).remove()
            const arr = state.dataset.ids.split(',')
            const newArr = arr.filter(e =>  e != box.dataset.id )
            state.dataset.ids = newArr.join(',')
            localStorage.selectedIds = state.dataset.ids
            download2();
        }
    });
 });  
};


 
const download2 = () => {
    const checkIdAge = [];
    const boxs = document.querySelectorAll('.mybox-age');
    boxs.forEach((box) =>{
        if (box.checked){
            checkIdAge.push(box.dataset.id);    
        }   
    });

    const state = document.querySelector('#state')
    const ids = state.dataset.ids.split(',')
    const result = [...checkIdAge, ...ids]
        
    state.dataset.ids = result.filter((item, index) => result.indexOf(item) === index)
}

export { fetchAges };