
const loadIcon = () => {

const {["log"]: _cl} = console; // Console.log wrapper

// SVG icons in the icons file

// Get the #icons container
let iconsContainer = document.querySelector("#icons");

// Used size of the icons
let usedHeight = 0;

let base_row = document.querySelector(".iconRow");
shuffleIcons(base_row);


function addIcons() {
    let new_row = base_row.cloneNode(true);
    iconsContainer.appendChild(new_row)

    shuffleIcons(new_row);

    usedHeight += 48;
}

function shuffleIcons(row) {
    // Shuffle icons
    for (let i = row.children.length; i >= 0; i--) {
        row.appendChild(row.children[Math.random() * i | 0]);
    }
}

// Get the #icons container height
let iconsContainerHeight = iconsContainer.offsetHeight;

// While usedHeight is less than iconsContainerHeight add icons
while (usedHeight < iconsContainerHeight) addIcons();

// Remove random icons from icons rows
function removeRandomIcons() {
    const iconRows = document.querySelectorAll(".iconRow");

    iconRows.forEach((iconRow) => {
        const iconBoxes = iconRow.querySelectorAll(".iconBox");

        iconBoxes.forEach((iconBox) => {
            if (Math.random() > 0.9) iconBox.remove();
        });
    });
}

removeRandomIcons();

// Set a random scale of the icons
function setRandomScale() {
    const iconRows = document.querySelectorAll(".iconRow");

    iconRows.forEach((iconRow) => {
        const iconBoxes = iconRow.querySelectorAll(".iconBox");

        iconBoxes.forEach((iconBox) => {
            iconBox.style.transform = `scale(${(Math.random() + 0.5) * 1.25})`;
            iconBox.style.transform += `translate(${Math.random() * 25}px, ${
                Math.random() * 25
            }px)`;
        });
    });
}

setRandomScale();
}


export {loadIcon};
