//charles toggle

const toggleCheck = () => {
    let urlEnd = "";
    const toggle = document.querySelectorAll('#options-body input');
    toggle.forEach((toggle) => {
        if(toggle.getAttribute('type') === 'checkbox') {
            if(toggle.checked) {
                urlEnd += `&${toggle.getAttribute('id')}=1`;
            }
        }
    });
    if(document.getElementById("search-plaintextFilter").value.length !== 0)
    {
        urlEnd += `&filterPlainText=${document.getElementById("search-plaintextFilter").value}`;
    }
    return urlEnd;
}

// fin toggle

const countList = document.querySelector('#count');
// const countList = null; a travailler  (faire comme search)



// commentaire charles


// const insertcount = (data) => {
//     data.Search.forEach((count) => {
//         const caTag = `
//         <li>
//             <p>${count.companyCount}</p>
//         </li>`;
//         countList.insertAdjacentHTML('beforeend', infos);
//     });
// };


const fetchComptages = () => {
    const countList = document.querySelector('#count');
    const current_user = document.getElementById("current-user").innerText;
    //charles toggle
    let urlEnd = toggleCheck();
    if(urlEnd !== "") {
        urlEnd = `&${urlEnd.slice(1)}`;
    }
    // fin toggle
    console.log("endUrl = " + urlEnd);
    console.log('wesh) ')

    fetch(`/tools/comptage?selected_ids=${localStorage.selectedIds}${urlEnd}`)

      .then(response => response.json())
      .then((data) => {
        console.log(data)
          window.lol = data
        // data.forEach((count) => {

            
            
            if (data.companyCount  == null ) {
                const infos = `<li class="list-inline-item">
                 ${"pas de potentiel"}
                </li>`;
                countList.insertAdjacentHTML("beforeend", infos);
            } else  {
                 
            const infos = `<li class="list-inline-item">
            ${data.companyCount} ${"sociétés"}
            </li>`;
            countList.insertAdjacentHTML("beforeend", infos);
            } 

            displayPrice(data.companyCount)

   
        // });
        // fetchInput();
    });
};

function displayPrice(societe_value) {
    const afficheur = document.getElementById("calcul_price");
    const btn = document.getElementById("calcul_price_btn");
    if (societe_value <= 200000)
    {
        afficheur.innerText = "("+societe_value+" sociétés | "+calculPrice(societe_value)+"€ )";
    }
    else
    {
        afficheur.innerText = "("+200000+" sociétés | "+calculPrice(200000)+"€ )";
    }
    
    let urlEnd = toggleCheck();
    if(urlEnd !== "") {
        urlEnd = `&${urlEnd.slice(1)}`;
    }
    btn.href = `/checkout/create?prix_calcule=${localStorage.selectedIds + urlEnd.replaceAll("&", "_")}`;
}

function calculPrice(societe_value) {
    const initialPrice = Math.max(1, Math.min(societe_value, 100) *
    0.2
    + (societe_value > 100 ? (Math.min(societe_value, 1000) - 100) *
    0.15 : 0)
    + (societe_value > 1000 ? (Math.min(societe_value, 10000) - 1000) *
    0.025 : 0)
    + (societe_value > 10000 ? (societe_value - 10000) * 0.015 : 0));
    let price = initialPrice;
    price += initialPrice * 0.025;
    price += initialPrice * 0.025 * 2;
    const final_value = (parseInt(price/5) === 0) ? 1 : parseInt(price/5);
    return final_value;
}

const fetchCompatgeOnClick = () => {
    const countList = document.querySelector('#count');
    const btn = document.getElementById('comptage')
    if (btn) {
        btn.addEventListener('click', () => {
        countList.innerHTML = "";
        fetchComptages()
    })
    }
}
    
    
//     const comptage = document.querySelector('#comptage');
//     // form.addEventListener('click', (event) => {
//     // event.preventDefault();
//     // list.innerHTML = '';
//     // }
  
//   fetchComptages(input.value);



export { fetchCompatgeOnClick };





// window.selectedIds