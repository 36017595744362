const sign = () => {

 

    let chkBoxs = document.getElementById("termsChkbxs");
    let btn7 = document.getElementById("someBtn7");
  
    
    chkBoxs.addEventListener("click", function() {
      if(this.checked == true) {
        btn7.classList.remove("disabled");
      } else {
        btn7.classList.add("disabled");
      }
    });



       
 
 }
 
  
 
  
 
 export { sign };