const filter = document.querySelector('#net');
// const panier = document.querySelectorAll('#panier');

const insertNet = (data) => {
    data.Search.forEach((net) => {
        const netTag = `
        <li>
            <p>${net.caption}</p>
        </li>`;
        filter.insertAdjacentHTML('beforeend', netTag);
    });
};

// fetch data api

const fetchNets = () => {
    

    fetch(`/tools/net`)
        .then(response => response.json())
        .then((data) => {
            data.forEach((net) => {
                const infos = `
                    <li class="list-inline-item">
                        <input type="checkbox" class=mybox-net data-id="${net.id}" data-caption="${net.caption}" value="box1"></input> ${net.caption}
                    </li>`;
                nets.insertAdjacentHTML("beforeend", infos);
            });
            fetchNet();
        });
};

const addToBasket = (dataCaption, id) => {  
    const panier = document.getElementById('panier');
    const infos = `<li class="list-inline-item" id="info-${id}" style="display: inline-block;">
        <i class="fa-solid fa-hand-holding-dollar" style="vertical-align: middle;"></i>
        <p style="display: inline-block; vertical-align: middle; margin: 0 0 0 5px;">${dataCaption}</p>
    </li>`;
    panier.insertAdjacentHTML("beforeend", infos);
};

const fetchNet = () => {
    const boxs = document.querySelectorAll('.mybox-net');
    boxs.forEach((box) =>{
        box.addEventListener('click', (event) => {
            if (box.checked) {
                addToBasket(box.dataset.caption, box.dataset.id)
                downloadNet();
                localStorage.selectedIds = state.dataset.ids
            } else {
                const id = box.dataset.id;
                document.querySelector(`#info-${id}`).remove();
                const arr = state.dataset.ids.split(',');
                const newArr = arr.filter(e =>  e != box.dataset.id);
                state.dataset.ids = newArr.join(',');
                localStorage.selectedIds = state.dataset.ids;
                downloadNet();
            }
        });
    });  
};

const downloadNet = () => {
    const checkedNetIds = [];
    const boxs = document.querySelectorAll('.mybox-net');
    boxs.forEach((box) =>{
        if (box.checked){
            checkedNetIds.push(box.dataset.id);    
        }   
    });

    const state = document.querySelector('#state');
    const ids = state.dataset.ids.split(',');
    const result = [...checkedNetIds, ...ids];
        
    state.dataset.ids = result.filter((item, index) => result.indexOf(item) === index);
};

export { fetchNets };
