const faq = () => {
  $(".faq-plus").on('click', function (e) {
    e.stopPropagation(); // Empêche la propagation de l'événement
    $(this).parent().parent().find('.faq-body').slideToggle();
  });

  $(".faq-item h4").on('click', function () {
    $(this).parent().find('.faq-body').slideToggle();
  });
}

export { faq };