const search = () => {
  const list = document.querySelector('#results');
  const form = document.querySelector('#search-form');
  const input = document.querySelector('#search-input');

  if (form) {
    form.addEventListener('submit', (event) => {
      event.preventDefault();
      list.innerHTML = '';
      fetchCpys(input.value);
    });
  }
  
  const checkBoxesIfAlreadySelected = () => {
    const boxes = document.querySelectorAll('.mybox');
    boxes.forEach((box) => {
      if (state.dataset.ids.split(',').includes(box.dataset.id)) {
        box.checked = true
      }
    })
  }

  // document.querySelector('#download').addEventListener('click', (evt) => {
  //   evt.preventDefault();
  //   evt.stopPropagation();
  
  //   console.log('state:', state)
  //   console.log(state.dataset.ids)
  // })


  ///
  // const insertCpys = (data) => {
  //   data.Search.forEach((result) => {
  //     const cpyTag = `<li>
  //       <p>${result.caption}</p>
  //     </li>`;
  //     list.insertAdjacentHTML('beforeend', infos);
  //   });
  // };

  
  
  const fetchCpys = (query) => {

    fetch(`/tools/company_activities?query=${query}`)
  
      .then(response => response.json())
      .then((data) => {
        // const newArray = [];
        data.forEach((result) => {
          // if (newArray.includes(result.caption)) {
          //   return;
          // }
          
          const infos = `<li class="list-inline-item">
           <input type="checkbox" class=mybox data-id="${result.id}" data-caption="${result.caption}" value="box1"></input> ${result.caption}
          </li>`;
          results.insertAdjacentHTML("beforeend", infos); 
          // newArray.push(result.caption)

        });
        checkBoxesIfAlreadySelected()
        fetchInput();
        
       
      });
     
  };
  
  fetchCpys('')

  function debounce(func, delay) {
    let debounceTimer;
    return function() {
      const context = this;
      const args = arguments;
      clearTimeout(debounceTimer);
      debounceTimer = setTimeout(() => func.apply(context, args), delay);
    };
  }
  
  const debouncedFetchCpys = debounce(function(inputValue) {
    fetchCpys(inputValue);
    list.innerHTML = '';
  }, 500);
  
  input.addEventListener('keyup', function() {
    debouncedFetchCpys(this.value);
  });

  const addToBasket = (dataCaption, id) => {  
    const panier = document.getElementById('panier');
    if (!panier.querySelector(`#info-${id}`)) {

      const infos = `<li class="list-inline-item" id="info-${id}" style="display: flex; align-items: center;">
      <i class="fas fa-briefcase" style="display: inline-block; font-size: 1.2em; vertical-align: middle; margin-right: 5px;"></i>
      <p style="display: inline-block; vertical-align: middle; margin: 0;">${dataCaption}</p>
    </li>`;
      // const infos = `<li class="list-inline-item" id="info-${id}">
      // <p> ${dataCaption}</p>
      // </li>`;
      panier.insertAdjacentHTML("beforeend", infos);
    }
  };
  
  // Mettre les ids en dataset des forms, Puis les récuperer sur le click sur chaque checkboxes et les stocker dans un array qu'on enverra en submitant sur un bouton
  
  const fetchInput = () => {
  
    const boxs = document.querySelectorAll('.mybox');
    boxs.forEach((box) =>{
      box.addEventListener('click', (event) => {
        if (box.checked) {
          addToBasket(box.dataset.caption, box.dataset.id)
        } else {
          const id = box.dataset.id
          document.querySelector(`#info-${id}`).remove()
          const arr = state.dataset.ids.split(',')
          // const newArr = arr.filter(e =>  e != box.dataset.id )
          // state.dataset.ids = newArr.join(',')
          if (arr.includes(box.dataset.id) === false) {
            const newArr = arr.filter(e => e != box.dataset.id)
            state.dataset.ids = newArr.join(',')
        }
        }
        setStateDataIds();
        localStorage.selectedIds = state.dataset.ids
      });
    });  
  };


  const setStateDataIds = () => {

    const checkId = [];
    const boxs = document.querySelectorAll('.mybox');
    boxs.forEach((box) => {
        if (box.checked) {
            checkId.push(box.dataset.id);
        } else {
            checkId.filter((id) => {
                id === box.dataset.id
            });
        }
    });

    const state = document.querySelector('#state')
    const panier = document.getElementById('panier')
    const idInCart = []

    for (let i = 0; i < panier.childNodes.length; i++) {
        let child = panier.childNodes[i]
        if (child.nodeName == 'LI') {
            let getId = child.id.slice(child.id.length - 4, child.id.length)
            if (idInCart.includes(getId) === false) {
                idInCart.push(getId)
            }
        }
    }
    state.dataset.ids = idInCart
}

  //------ ancien code de charles
  
  // const setStateDataIds = () => {
  
  //   const checkId = [];
  //   const boxs = document.querySelectorAll('.mybox');
  //   boxs.forEach((box) =>{
  //     if (box.checked){
  //       checkId.push(box.dataset.id);
  //     } else {
  //       checkId.filter((id) => {
  //         id === box.dataset.id
  //       });
  //     }
  //   });
  
  //   const state = document.querySelector('#state')
  //   const ids = state.dataset.ids.split(',')
  //   const result = [...checkId, ...ids]
        
  //   state.dataset.ids = result.filter((item, index) => result.indexOf(item) === index)
  // }
  

  // ------------ fin code de charles
  
  // const boxs = document.querySelectorAll('.mybox');
  // console.log(boxs);
  //  boxs.forEach((box) =>{
  
  
  // const liste = document.querySelector('#selection');
  // liste.insertAdjacentHTML('beforeend', '<li> ${result.id} </li>');
  
}
export { search };  