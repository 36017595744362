
const toggleCheck = () => {
  let urlEnd = "";
  const toggle = document.querySelectorAll('#options-body input');
  toggle.forEach((toggle) => {
      if(toggle.getAttribute('type') === 'checkbox') {
          if(toggle.checked) {
              urlEnd += `&${toggle.getAttribute('id')}=1`;
          }
      }
  });
  if(document.getElementById("search-plaintextFilter").value.length !== 0)
  {
      urlEnd += `&filterPlainText=${document.getElementById("search-plaintextFilter").value}`;
  }
  return urlEnd;
}


const fetchSelections = () => {

  
  const download = document.querySelector('#download');
  download.innerText = 'en cours....';
  download.setAttribute('disabled','true');
}


const openModalJobs = () => {
  document.getElementById("backdropDownload").style.display = "block"
  document.getElementById("downloadModal").style.display = "block"
  document.getElementById("downloadModal").className += "show"
}

const closeModalJobs = () => {
  document.getElementById("backdropDownload").style.display = "none"
  document.getElementById("downloadModal").style.display = "none"
  document.getElementById("downloadModal").className += document.getElementById("downloadModal").className.replace("show", "")
}


const fetchJobsOnClicks = () => {
  const btn = document.getElementById("download");
  if (btn) {
    btn.addEventListener("click", () => {
      // countList.innerHTML = "";
      if (document.getElementById("name").value.length == 0) {
        alert("pas de nom à votre fichier");
      }else if(document.getElementById("name").value.length > 0 && document.getElementById("name").value.match(/[^a-z0-9_]/gi) != null){
        alert("Votre nom de fichier ne doit contenir que des lettres, underscore ( _ ) et des chiffres.");
      }else if (!localStorage.selectedIds) {
        alert("aucun critère sélectionné");
      }else {
         
        openModalJobs()
        setTimeout(function() { closeModalJobs() }, 45000);

        fetchJobs();

        
        fetchSelections();
        
      }
    });
  }
};

const fetchJobs = (event) => {
  
  const current_user = document.getElementById("current-user").innerText;
  fetch("/tools/add_compteur",  )
  .then((data) => { 
    if (data.status === 204)
    {
  const current_user = document.getElementById("current-user").innerText;
  const save_name = document.getElementById("name").value;
   const endUrl = toggleCheck();
   console.log("endUrl = " + endUrl);
  console.log(save_name);
  console.log(localStorage.selectedIds);

    fetch(`/tools/jobs?selected_ids=${localStorage.selectedIds}&save_name=${save_name}${endUrl}`)

    

      .then(response => response.json())
      .then((data) => {
        const loader = document.querySelector('#futur-loader');
        loader.innerHTML="<div class='loader'> </div>";

        setTimeout(() => {
          fetchStatus();
        }, 45000)

      });
    }

  });
  };








const fetchStatus = () => {
  const status = document.querySelector('#status');
  console.log('wesh')
  const current_user = document.getElementById("current-user").innerText;


  fetch(`/tools/statut`)
    
    .then(response => response.json())
    .then((data) => {
        console.log(data)
      // countList.innerHTML = "";
      // data.jobs.forEach((companie) => {
      //     const infos = `<li class="list-inline-item"> <p> ${companie.name1} ${companie.address1} ${companie.city}</p></li>`;
      //     countList.insertAdjacentHTML("beforeend", infos);
      // });
      // fetchInput();

      // const infos = `<li class="list-inline-item"> <p> ${job.created}  ${job.url} </p></li>`;
      // status.insertAdjacentHTML("beforeend", infos);
      const loader = document.querySelector('#futur-loader');
      loader.innerHTML=""
      // pour tous les avoir avec un each 
      // data.jobs.forEach((job) => {
      //     const infos = `<li class="list-inline-item w-100 mb-2"> <a class="btn btn-success" href = "${job.url}">fichier prêt : ${job.caption} </a></li>`;
      //     status.insertAdjacentHTML("beforeend", infos);
      // });


      //        const infos = `<li class="list-inline-item w-100 mb-2"> <a class="btn btn-success" href = "/tools/download?file_url=${data.jobs[0].url}">fichier prêt : '${data.jobs[0].caption}' </a></li>`;

        const infos = `<li class="list-inline-item w-100 mb-2"> <a class="btn btn-success"   href = "/tools/download?file_url=${data.jobs[0].url}" onclick="return !window.open(this.href, 'pop', 'width=400,height=200,left=120,top=120');" >fichier prêt : '${data.jobs[0].caption}' </a></li>`;
      status.insertAdjacentHTML("beforeend", infos);
      const download = document.querySelector('#download');
      download.innerText = 'terminé';
      download.setAttribute('disabled','true');
  });
};




export { fetchJobsOnClicks };