const openModal = () => {
    document.getElementById("backdrop").style.display = "block"
    document.getElementById("emptyDownloadFile").style.display = "block"
    document.getElementById("emptyDownloadFile").className += "show"
}

const closeModal = () => {
    document.getElementById("backdrop").style.display = "none"
    document.getElementById("emptyDownloadFile").style.display = "none"
    document.getElementById("emptyDownloadFile").className += document.getElementById("emptyDownloadFile").className.replace("show", "")
}

const fetchDashboard = () => {
    const dash = document.querySelector('#dash');
    const count = document.querySelector('#countnb');
    const last = document.querySelector('#last');
    const down = document.querySelector('#downloadfinal');
    const avant = document.querySelector('#enavant');
    const current_user = document.getElementById("current-user").innerText;
    

        
    fetch(`/tools/statut`)
    
      .then(response => response.json())
      .then((data) => {
          console.log(data)

          const rem = ` ${data.count}`;
          if (Number(rem) === 0) {
              const closeModalBtn = document.getElementById("closeModal")
              closeModalBtn.addEventListener("click", () => {
                  closeModal()
              })
              openModal()
              last.style.display = "none"
              down.style.display = "none"
          }

    // const cc = ` ${data.count} <b>test</b>`;
    // count.insertAdjacentHTML("afterbegin", cc);

    if (data.count<2) {
        const cc = ` ${data.count} <b>fichier</b>`;
        count.insertAdjacentHTML("afterbegin", cc);
    } else  {
        const cc = ` ${data.count} <b>fichiers</b>`;
        count.insertAdjacentHTML("afterbegin", cc);
    } 


 

    const etat = `${data.jobs[0].statusCaption}`;
    console.log(etat);
    if (etat === "WAITING") {
        last.style.background = "red"
    } else if (etat === "STARTED") {
        last.style.background = "orange"
    } else if (etat === "SUCCESS") {
        last.style.background = "#28a745"
    }else if (etat === "NO DATA") {
        last.style.background = "blue"
    } 
    last.insertAdjacentHTML("beforeend", etat);


    //   const downdash = ` <a  href = "/tools/download?file_url=${data.jobs[0].url}" onclick="return !window.open(this.href, 'pop', 'width=400,height=200,left=120,top=120');"><b>Télécharger le dernier fichier</b></a>`;

   const downdash = ` <a  href = "/tools/download?file_url=${data.jobs[0].url}" onclick="return !window.open(this.href, 'pop', 'width=400,height=200,left=120,top=120');"><b>Télécharger le dernier fichier</b></a>`;
   console.log(downdash);
    down.insertAdjacentHTML("beforeend", downdash);

    const avantici= `${data.jobs[0].caption}`;
    console.log(downdash);
     avant.insertAdjacentHTML("beforeend", avantici);
 



 



data.jobs.forEach((job) => {
    const infos = `<li >  <a  href = "/tools/download?file_url=${job.url}" target="_blank"> <b>${job.caption}</b>(${job.created})</a> </li>`;
    dash.insertAdjacentHTML("beforeend", infos);
});

})
}




export { fetchDashboard };