
const scrollp = () => {


 
 function checkOffset() {
  if($('#panier-count').offset().top + $('#panier-count').height() 
                                         >= $('#foote').offset().top )
      $('#panier-count').css('position', 'absolute');
  if($(document).scrollTop() + window.innerHeight < $('#foote').offset().top + 180)
      $('#panier-count').css('position', 'fixed'); // restore when you scroll up

      if(window.outerWidth < 1000) {
        $('#panier-count').css('position', 'relative')
      }
 
}
$(document).scroll(function() {
  checkOffset();
}); 





} 

  export { scrollp };

 