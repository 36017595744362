const nafs = () => {
  const list = document.querySelector('#nafs');
  const form = document.querySelector('#codenaf');
  const input = document.querySelector('#search-naf');

  if (form) {
    form.addEventListener('submit', (event) => {
      event.preventDefault();
      list.innerHTML = '';
      fetchNafs(input.value);
    });
  }


  const checkBoxesIfAlreadySelected = () => {
    const boxes = document.querySelectorAll('.mybox-naf');
    boxes.forEach((box) => {
      if (state.dataset.ids.split(',').includes(box.dataset.id)) {
        box.checked = true
      }
    })
  }

  const fetchNafs = (query) => {
    fetch(`/tools/company_activities?query=${query}`)

      .then(response => response.json())
      .then((data) => {
        //charles v1
        // const newArray = [];
        // charles v2
        
  //       data.forEach((naf) => {
  //         const infos = `<li class="list-inline-item">
  //         <input type="checkbox" class=mybox-naf data-id="${naf.id}" data-caption="${naf.alias}" value="box1"></input> ${naf.alias}
  //         </li>`;
  //         list.insertAdjacentHTML("beforeend", infos); 
  //       });
  //       checkBoxesIfAlreadySelected()
  //       fetchInput();


  //     });

  // };

  Array.from(data).sort((a, b) => a.alias.localeCompare(b.alias)).forEach((naf) => {
    const infos = `<li class="list-inline-item">
    <input type="checkbox" class=mybox-naf data-id="${naf.id}" data-caption="${naf.alias}" value="box1"></input> ${naf.alias}
    </li>`;
    list.insertAdjacentHTML("beforeend", infos);
  });
  checkBoxesIfAlreadySelected()
  fetchInput();


});

};

  fetchNafs('')

  const addToBasket = (dataCaption, id) => {
    const panier = document.getElementById('panier');
    if (!panier.querySelector(`#info-nafs-${id}`)) {
      const infos = `<li class="list-inline-item" id="info-nafs-${id}">
    <i class="fas fa-industry" style="vertical-align: middle;"></i>
    <p style="display: inline-block; vertical-align: middle; margin: 0 0 0 5px;">${dataCaption}</p>
  </li>`;

      // const infos = `<li class="list-inline-item" id="info-nafs-${id}">
      // <p> ${dataCaption}</p>
      // </li>`;
      panier.insertAdjacentHTML("beforeend", infos);
    }
  };


  const fetchInput = () => {

    const boxs = document.querySelectorAll('.mybox-naf');
    boxs.forEach((box) =>{
      box.addEventListener('click', (event) => {
        if (box.checked) {
          addToBasket(box.dataset.caption, box.dataset.id)
        } else {
          const id = box.dataset.id
          document.querySelector(`#info-nafs-${id}`).remove()
          // document.querySelector(`#info-${id}`).remove()
          const arr = state.dataset.ids.split(',')
          // const newArr = arr.filter(e =>  e != box.dataset.id )
          // state.dataset.ids = newArr.join(',')
          if (arr.includes(box.dataset.id) === false) {
            const newArr = arr.filter(e => e != box.dataset.id)
            state.dataset.ids = newArr.join(',')
        }
        }
        setStateDataIds();
        localStorage.selectedIds = state.dataset.ids
      });
    });
  };

  const setStateDataIds = () => {

    const checkId = [];
    const boxs = document.querySelectorAll('.mybox-naf');
    boxs.forEach((box) => {
        if (box.checked) {
            checkId.push(box.dataset.id);
        } else {
            checkId.filter((id) => {
                id === box.dataset.id
            });
        }
    });

    const state = document.querySelector('#state')
    const panier = document.getElementById('panier')
    const idInCart = []

    for (let i = 0; i < panier.childNodes.length; i++) {
        let child = panier.childNodes[i]
        if (child.nodeName == 'LI') {
            let getId = child.id.slice(child.id.length - 4, child.id.length)
            if (idInCart.includes(getId) === false) {
                idInCart.push(getId)
            }
        }
    }
    state.dataset.ids = idInCart
}
  //------ ancien code de charles

  // const setStateDataIds = () => {

  //   const checkId = [];
  //   const boxs = document.querySelectorAll('.mybox-naf');
  //   boxs.forEach((box) =>{
  //     if (box.checked){
  //       checkId.push(box.dataset.id);
  //     } else {
  //       checkId.filter((id) => {
  //         id === box.dataset.id
  //       });
  //     }
  //   });



  //   const state = document.querySelector('#state')
  //   const ids = state.dataset.ids.split(',')
  //   const naf = [...checkId, ...ids]

  //   state.dataset.ids = naf.filter((item, index) => naf.indexOf(item) === index)
  // }
//------ ancien code de charles

}

  export { nafs } ;