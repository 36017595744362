const filter = document.querySelector('#nombres');

const insertNombres = (data) => {
  data.Search.forEach((nombre) => {
    const nombreTag = 
    `<li>
        <p>${nombre.caption}</p>
    </li>`;
    filter.insertAdjacentHTML('beforeend', infos);
  });
};


const fetchNombres = () => {

fetch(`/tools/nombre`)
    
    .then(response => response.json())
    .then((data) => {
      data.forEach((nombre) => {
        const infos = `<li class="list-inline-item">
         <input type="checkbox" class=mybox-nombre data-id="${nombre.id}" data-caption="${nombre.caption}" value="box1"></input> ${nombre.caption}
        </li>`;
        nombres.insertAdjacentHTML("beforeend", infos);
      });
      fetchNombre();
    });
};


// const addToBasket = (dataCaption, id) => {  
//   const panier = document.getElementById('panier');
//   const infos = `<li class="list-inline-item" id="info-${id}">
//   <p> ${dataCaption}</p>
//   </li>`;
//   panier.insertAdjacentHTML("beforeend", infos);
// };

const addToBasket = (dataCaption, id) => {  
  const panier = document.getElementById('panier');
  const infos = `<li class="list-inline-item" id="info-${id}" style="display: inline-block;">
  <i class="fa fa-sort-numeric-desc" style="vertical-align: middle;"></i><p style="display: inline-block; vertical-align: middle; margin: 0 0 0 5px;">${dataCaption}</p>
</li>`;
  panier.insertAdjacentHTML("beforeend", infos);
};


const fetchNombre = () => {

  const boxs = document.querySelectorAll('.mybox-nombre');
  boxs.forEach((box) =>{
      box.addEventListener('click', (event) => {
          if (box.checked) {
              addToBasket(box.dataset.caption, box.dataset.id)
              download4();
              localStorage.selectedIds = state.dataset.ids
          } else {
            const id = box.dataset.id
            document.querySelector(`#info-${id}`).remove()
            const arr = state.dataset.ids.split(',')
            const newArr = arr.filter(e =>  e != box.dataset.id )
            state.dataset.ids = newArr.join(',')
            localStorage.selectedIds = state.dataset.ids
            download4();

          }
          // console.log(event.target.dataset.id);
          // console.log(event.target.checked);
      });
   });  
  };


// const fetchEffectif = () => {

//     const boxs = document.querySelectorAll('.mybox-nombre');
//     boxs.forEach((box) =>{
//     box.addEventListener('click', (event) => {
//     // console.log(event.target.dataset.id);
//     // console.log(event.target.checked);
//     download4();
//     });
//      });  
//     }
    
      const download4 = () => {
    
        const checkIdEffectif = [];
        const boxs = document.querySelectorAll('.mybox-nombre');
        boxs.forEach((box) =>{
        if (box.checked){
          checkIdEffectif.push(box.dataset.id);
        }
        });
       
        const state = document.querySelector('#state')
        const ids = state.dataset.ids.split(',')
        const result = [...checkIdEffectif, ...ids]
            
        state.dataset.ids = result.filter((item, index) => result.indexOf(item) === index)
        }
    
        /////
        // document.querySelector('#download').addEventListener('click', (evt) => {
        //     evt.preventDefault();
        //     evt.stopPropagation();
    
        //     console.log(state.dataset.ids)
        // })
    /////



export { fetchNombres };