const googles = () => {
  const list = document.querySelector('#googles');
  const form = document.querySelector('#codegoogle');
  const input = document.querySelector('#search-google');

  if (form) {
    form.addEventListener('submit', (event) => {
      event.preventDefault();
      list.innerHTML = '';
      fetchGoogle(input.value);
    });
  }


  const checkBoxesIfAlreadySelected = () => {
    const boxes = document.querySelectorAll('.mybox-google');
    boxes.forEach((box) => {
      if (state.dataset.ids.split(',').includes(box.dataset.id)) {
        box.checked = true
      }
    })
  }

  const fetchGoogle = (query) => {
    fetch(`/tools/google?query=${query}`)

      .then(response => response.json())
      .then((data) => {
        //charles v1
        // const newArray = [];
        // charles v2
        
  //       data.forEach((google) => {
  //         const infos = `<li class="list-inline-item">
  //         <input type="checkbox" class=mybox-google data-id="${google.id}" data-caption="${google.alias}" value="box1"></input> ${google.alias}
  //         </li>`;
  //         list.insertAdjacentHTML("beforeend", infos); 
  //       });
  //       checkBoxesIfAlreadySelected()
  //       fetchInput();


  //     });

  // };

  Array.from(data).forEach((google) => {
    const infos = `<li class="list-inline-item">
    <input type="checkbox" class=mybox-google data-id="${google.id}" data-caption="${google.caption}" value="box1"></input> ${google.caption}
    </li>`;
    list.insertAdjacentHTML("beforeend", infos);
  });
  checkBoxesIfAlreadySelected()
  fetchInput();


});

};

  fetchGoogle('')

  const addToBasket = (dataCaption, id) => {
    const panier = document.getElementById('panier');
    if (!panier.querySelector(`#info-googles-${id}`)) {
      const infos = `<li class="list-inline-item" id="info-googles-${id}">
    <i class="fas fa-gavel" style="vertical-align: middle;"></i>
    <p style="display: inline-block; vertical-align: middle; margin: 0 0 0 5px;">${dataCaption}</p>
  </li>`;

      // const infos = `<li class="list-inline-item" id="info-googles-${id}">
      // <p> ${dataCaption}</p>
      // </li>`;
      panier.insertAdjacentHTML("beforeend", infos);
    }
  };


  const fetchInput = () => {

    const boxs = document.querySelectorAll('.mybox-google');
    boxs.forEach((box) =>{
      box.addEventListener('click', (event) => {
        if (box.checked) {
          addToBasket(box.dataset.caption, box.dataset.id)
        } else {
          const id = box.dataset.id
          document.querySelector(`#info-googles-${id}`).remove()
          // document.querySelector(`#info-${id}`).remove()
          const arr = state.dataset.ids.split(',')
          // const newArr = arr.filter(e =>  e != box.dataset.id )
          // state.dataset.ids = newArr.join(',')
          if (arr.includes(box.dataset.id) === false) {
            const newArr = arr.filter(e => e != box.dataset.id)
            state.dataset.ids = newArr.join(',')
        }
        }
        setStateDataIds();
        localStorage.selectedIds = state.dataset.ids
      });
    });
  };

  const setStateDataIds = () => {

    const checkId = [];
    const boxs = document.querySelectorAll('.mybox-google');
    boxs.forEach((box) => {
        if (box.checked) {
            checkId.push(box.dataset.id);
        } else {
            checkId.filter((id) => {
                id === box.dataset.id
            });
        }
    });

    const state = document.querySelector('#state')
    const panier = document.getElementById('panier')
    const idInCart = []

    for (let i = 0; i < panier.childNodes.length; i++) {
        let child = panier.childNodes[i]
        if (child.nodeName == 'LI') {
            let getId = child.id.includes("googles") ? child.id.split("-")[2] : child.id.split("-")[1]
            if (idInCart.includes(getId) === false) {
                idInCart.push(getId)
            }
        }
    }
    state.dataset.ids = idInCart
}
  //------ ancien code de charles

  // const setStateDataIds = () => {

  //   const checkId = [];
  //   const boxs = document.querySelectorAll('.mybox-google');
  //   boxs.forEach((box) =>{
  //     if (box.checked){
  //       checkId.push(box.dataset.id);
  //     } else {
  //       checkId.filter((id) => {
  //         id === box.dataset.id
  //       });
  //     }
  //   });



  //   const state = document.querySelector('#state')
  //   const ids = state.dataset.ids.split(',')
  //   const google = [...checkId, ...ids]

  //   state.dataset.ids = google.filter((item, index) => google.indexOf(item) === index)
  // }
//------ ancien code de charles

}

  export { googles } ;