

// const insertCpys = (data) => {
    //     data.Search.forEach((sample) => {
//       const cpyTag = `<li>
//         <p>${sample.companies}</p>
//       </li>`;
//       list.insertAdjacentHTML('beforeend', infos);
//     });
//   };

const fetchStatusOnClick = () => {
    const status = document.querySelector('#status');
    const btn = document.getElementById('refresh')
    if (btn) {
        btn.addEventListener('click', () => {   
            status.innerHTML = "";
            fetchStatus()
        })
    }
}

const fetchStatus = () => {
    const status = document.querySelector('#status');
    console.log('wesh')
    const current_user = document.getElementById("current-user").innerText;
    fetch(`/tools/statut`)
        
      .then(response => response.json())
      .then((data) => {
          console.log(data)
        // countList.innerHTML = "";
        // data.jobs.forEach((companie) => {
        //     const infos = `<li class="list-inline-item"> <p> ${companie.name1} ${companie.address1} ${companie.city}</p></li>`;
        //     countList.insertAdjacentHTML("beforeend", infos);
        // });
        // fetchInput();

        // const infos = `<li class="list-inline-item"> <p> ${job.created}  ${job.url} </p></li>`;
        // status.insertAdjacentHTML("beforeend", infos);

        const loader = document.querySelector('#futur-loader');
        loader.innerHTML="<div class='loader'> </div>";
        
        setTimeout(() => {
            loader.innerHTML=""
            data.jobs.forEach((job) => {
                const infos = `<li class="list-inline-item w-100 mb-2"> <a class="btn btn-success" href = "${job.url}">fichier pret </a></li>`;
                status.insertAdjacentHTML("beforeend", infos);
            });
        }, 15000)



    });
};



export { fetchStatusOnClick };


