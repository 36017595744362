const filter = document.querySelector('#croissances');
// const panier = document.querySelectorAll('#panier');

const insertcroissances = (data) => {
    data.Search.forEach((croissance) => {
        const croissanceTag = `
        <li>
            <p>${croissance.caption}</p>
        </li>`;
        filter.insertAdjacentHTML('beforeend', infos);
    });
};

// fetch data api

const fetchCroissances = () => {

fetch(`/tools/croissance`)


    .then(response => response.json())
    .then((data) => {
        data.forEach((croissance) => {
            const infos = `
          <li class="list-inline-item">
            <input type="checkbox" class=mybox-croissance data-id="${croissance.id}" data-caption="${croissance.caption}" value="box1"></input> ${croissance.caption}
            </li>`;
            croissances.insertAdjacentHTML("beforeend", infos);
        });
        fetchCroissance();
    });
};


const addToBasket = (dataCaption, id) => {  
    const panier = document.getElementById('panier');
    const infos = `<li class="list-inline-item" id="info-${id}" style="display: inline-block;">
    <i class="fas fa-arrow-up-right-dots style="vertical-align: middle;"></i>
    <p style="display: inline-block; vertical-align: middle; margin: 0 0 0 5px;">${dataCaption}</p>
  </li>`;
    panier.insertAdjacentHTML("beforeend", infos);
};

const fetchCroissance = () => {

const boxs = document.querySelectorAll('.mybox-croissance');
boxs.forEach((box) =>{
    box.addEventListener('click', (event) => {
        if (box.checked) {
            addToBasket(box.dataset.caption, box.dataset.id)
            download2();
            localStorage.selectedIds = state.dataset.ids
        } else {
            const id = box.dataset.id
            document.querySelector(`#info-${id}`).remove()
            const arr = state.dataset.ids.split(',')
            const newArr = arr.filter(e =>  e != box.dataset.id )
            state.dataset.ids = newArr.join(',')
            localStorage.selectedIds = state.dataset.ids
            download2();
        }
    });
 });  
};


 
const download2 = () => {
    const checkIdCroissance = [];
    const boxs = document.querySelectorAll('.mybox-croissance');
    boxs.forEach((box) =>{
        if (box.checked){
            checkIdCroissance.push(box.dataset.id);    
        }   
    });

    const state = document.querySelector('#state')
    const ids = state.dataset.ids.split(',')
    const result = [...checkIdCroissance, ...ids]
        
    state.dataset.ids = result.filter((item, index) => result.indexOf(item) === index)
}

export { fetchCroissances };