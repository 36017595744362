// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.


import Rails from "@rails/ujs"
import Turbolinks from "turbolinks"




import * as ActiveStorage from "@rails/activestorage"
import "channels"
import "bootstrap"






require('datatables.net')


Rails.start() 
Turbolinks.start()
ActiveStorage.start()


// ----------------------------------------------------
// Note(lewagon): ABOVE IS RAILS DEFAULT CONFIGURATION
// WRITE YOUR OWN JS STARTING FROM HERE 👇
// ----------------------------------------------------

// External imports

import { search } from '../channels/search.js';
import { fetchEchantillonOnClick } from '../channels/sample.js';
import { fetchCompatgeOnClick } from '../channels/comptage.js';
import { fetchJobsOnClicks } from '../channels/jobs.js';
import { fetchStatusOnClick } from '../channels/status.js';
import { nafs } from '../channels/naf.js';
import { geo } from '../channels/geo.js';
import { fetchEffectifs } from '../channels/effectif.js';
import { fetchClotures } from '../channels/cloture.js';
import { fetchCas } from '../channels/ca.js';
import { fetchNombres} from '../channels/nombre.js';
import { fetchCroissances} from '../channels/croissance.js';
import { fetchNets } from '../channels/net.js';
import { fetchDates } from '../channels/date.js';
import { loadIcon } from '../channels/icons.js';
import { fetchDashboard } from "../channels/dashboard.js";
import { faq } from "../channels/faq.js";
import { camembert } from "../channels/camembert.js";
import { scrollp } from "../channels/scrollp.js";
import { cgv } from "../channels/cgv.js";
import { sign } from "../channels/sign.js";
import { fetchAges } from '../channels/age.js';
import { conventions } from '../channels/convention.js';
import { legal_forms } from '../channels/legal_form.js';
import { googles } from '../channels/google.js';

// Internal imports, e.g:
// import { initSelect2 } from '../components/init_select2';

document.addEventListener('turbolinks:load', () => {
  localStorage.clear();
  

  if (document.querySelector('#panier')) {
    scrollp();
  }
 
  if (document.querySelector('#someBtn7')) {
    sign();
  }

  if (document.querySelector('#cgv1')) {
   cgv();
  }

  if (document.querySelector('#faq')) {
    faq();
  }

  if (document.querySelector('#myChart')) {
    camembert();
  }

  if (document.querySelector('#samples')) {
    fetchEchantillonOnClick();
  }

  if (document.querySelector('#dash')) {
   fetchDashboard();
  }

  if (document.querySelector('#compagnies')) {
     fetchEchantillonOnClick();
  }

  if (document.querySelector('#icons')) {
    loadIcon();
  }

  if (document.querySelector('#search-form')) {
    geo();
    fetchEffectifs();
    fetchCas();
    fetchDates();
    fetchCroissances();
    fetchClotures();
    fetchNets();
    fetchNombres();
    fetchCompatgeOnClick();
    scrollp();
    fetchAges();
  }
  
  if (document.querySelector('#search-form')) {
    search();
    nafs();
    conventions();
    googles();
    legal_forms();
    fetchJobsOnClicks();
    fetchStatusOnClick();
  }

});
    
window.pathFreeMoc = function() {
  location.assign("/free");
}

window.pathMoc = function() {
  location.assign("/tools");
}



window.copyDataAsCSV = function(companyId)  {
  let resultData = [];
  let list_id = ["name1", "name2", "nationalCode", "address2", "address1", "postcode", "city",
   "employeeRangeCaption", "turnover", "siteActivityAlias", "legalFormCaption", "url", "phone",
    "email", "socialFacebook", "socialLinkedin", "decideurFirstname", "decideurLastname", "decideurPosition"];

  list_id.forEach((id) => {
    if (document.getElementById(companyId+"-"+id).checked)
    {
      resultData.push(document.getElementById(companyId+"-"+id+"-text").innerText)
      navigator.clipboard.writeText(resultData);
    }
  })

  navigator.clipboard.writeText(resultData);


}   

document.addEventListener("DOMContentLoaded", function() {
  feather.replace();
});
