const toggleCheck = () => {
    let urlEnd = "";
    const toggle = document.querySelectorAll('#options-body input');
    toggle.forEach((toggle) => {
        if(toggle.getAttribute('type') === 'checkbox') {
            if(toggle.checked) {
                urlEnd += `&${toggle.getAttribute('id')}=1`;
            }
        }
    });
    if(document.getElementById("search-plaintextFilter").value.length !== 0)
    {
        urlEnd += `&filterPlainText=${document.getElementById("search-plaintextFilter").value}`;
    }
    return urlEnd;
}


const fetchEchantillons = () => {
    const countList = document.querySelector('#samples');
    let urlEndd = toggleCheck();
    if(urlEndd !== "") {
        urlEndd = `&${urlEndd.slice(1)}`;
    }
    fetch(`/tools/samples?selected_ids=${localStorage.selectedIds}${urlEndd}`)

    .then(response => response.json())
    .then((data) => {
        /**
         * Création du DataTable 
        **/
       
        $(function() {
            if ( $.fn.DataTable.isDataTable('#companies') ) {
                $('#companies').DataTable().destroy();
            }
            $('#companies').DataTable( {
                language: {
                    "sEmptyTable": "Aucune donnée disponible dans le tableau",
                    "sInfoEmpty": "Affichage de l'élément 0 à 0 sur 0 élément",
                    "sInfoFiltered": "(filtré à partir de _MAX_ éléments au total)",
                    "sInfoPostFix": "",
                    "sInfoThousands": ",",
                    "sLengthMenu": "Afficher _MENU_ éléments",
                    "sLoadingRecords": "Chargement...",
                    "sProcessing": "Traitement...",
                },
                paging: false, // Désactive la pagination
                searching: false, // Désactive la recherche
                // Supprimez la ligne suivante pour conserver le tri
                ordering: false, // Désactive le tri
                info: '', // Texte vide pour l'option "Showing 1 to 5 of 5 entries"
                data: data.companies,
                columns: [
                    { title: "Siret", data: "nationalCode"},
                    { title: "Nom société", data:"name1" },
                    { title: "Adresse" ,data:"address2"},
                    { title: "CP",data:"postcode" },
                    { title: "Ville", data:"city" },
                    { title: "Naf", data:"siteActivityAlias" },
                    { title: "Effectif", data:"employeeRangeCaption" },
                    { title: "CA", data:"turnover" },
                    { title: "Prénom", data:"decideurFirstname"},
                    { title: "Nom", data:"decideurLastname"},
                    { title: "Fonction", data:"decideurPosition"}
                ]
            } );
        });
        
} );
};



const fetchEchantillonOnClick = () => {
    const btn = document.getElementById('echantillon')
    const countList = document.querySelector('#samples');

    btn.addEventListener('click', () => {
        countList.innerHTML = "";
        fetchEchantillons()
    })
}


window.DatatableSample20 = function() {
    $('#table20').DataTable();
}

window.fetchEchantillonOnClick20 = function()  {

    let urlEndd = toggleCheck();
    if(urlEndd !== "") {
        urlEndd = `&${urlEndd.slice(1)}`;
    }
    location.assign('/sample20?selected_ids='+localStorage.selectedIds+''+urlEndd);
  }

//Appel de la fonction pour tester

export { fetchEchantillonOnClick };  