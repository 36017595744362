const filter = document.querySelector('#dates');
// const panier = document.querySelectorAll('#panier');

const insertdates = (data) => {
    data.Search.forEach((date) => {
        const dateTag = `
        <li>
            <p>${date.caption}</p>
        </li>`;
        filter.insertAdjacentHTML('beforeend', infos);
    });
};

// fetch data api

const fetchDates = () => {

fetch(`/tools/date`)


    .then(response => response.json())
    .then((data) => {
        data.forEach((date) => {
            const infos = `
          <li class="list-inline-item">
            <input type="checkbox" class=mybox-date data-id="${date.id}" data-caption="${date.caption}" value="box1"></input> ${date.caption}
            </li>`;
            dates.insertAdjacentHTML("beforeend", infos);
        });
        fetchDate();
    });
};


const addToBasket = (dataCaption, id) => {  
    const panier = document.getElementById('panier');
    const infos = `<li class="list-inline-item" id="info-${id}" style="display: inline-block;">
    <i class="fas fa-cake-candles style="vertical-align: middle;"></i>
    <p style="display: inline-block; vertical-align: middle; margin: 0 0 0 5px;">${dataCaption}</p>
  </li>`;
    panier.insertAdjacentHTML("beforeend", infos);
};

const fetchDate = () => {

const boxs = document.querySelectorAll('.mybox-date');
boxs.forEach((box) =>{
    box.addEventListener('click', (event) => {
        if (box.checked) {
            addToBasket(box.dataset.caption, box.dataset.id)
            download2();
            localStorage.selectedIds = state.dataset.ids
        } else {
            const id = box.dataset.id
            document.querySelector(`#info-${id}`).remove()
            const arr = state.dataset.ids.split(',')
            const newArr = arr.filter(e =>  e != box.dataset.id )
            state.dataset.ids = newArr.join(',')
            localStorage.selectedIds = state.dataset.ids
            download2();
        }
    });
 });  
};


 
const download2 = () => {
    const checkIdDate = [];
    const boxs = document.querySelectorAll('.mybox-date');
    boxs.forEach((box) =>{
        if (box.checked){
            checkIdDate.push(box.dataset.id);    
        }   
    });

    const state = document.querySelector('#state')
    const ids = state.dataset.ids.split(',')
    const result = [...checkIdDate, ...ids]
        
    state.dataset.ids = result.filter((item, index) => result.indexOf(item) === index)
}

export { fetchDates };