const filter = document.querySelector('#cas');
// const panier = document.querySelectorAll('#panier');

const insertcas = (data) => {
    data.Search.forEach((ca) => {
        const caTag = `
        <li>
            <p>${ca.caption}</p>
        </li>`;
        filter.insertAdjacentHTML('beforeend', infos);
    });
};

// fetch data api

const fetchCas = () => {

fetch(`/tools/ca`)


    .then(response => response.json())
    .then((data) => {
        data.forEach((ca) => {
            const infos = `
          <li class="list-inline-item">
            <input type="checkbox" class=mybox-ca data-id="${ca.id}" data-caption="${ca.caption}" value="box1"></input> ${ca.caption}
            </li>`;
            cas.insertAdjacentHTML("beforeend", infos);
        });
        fetchCa();
    });
};


const addToBasket = (dataCaption, id) => {  
    const panier = document.getElementById('panier');
    const infos = `<li class="list-inline-item" id="info-${id}" style="display: inline-block;">
    <i class="fas fa-money-bill-wave" style="vertical-align: middle;"></i>
    <p style="display: inline-block; vertical-align: middle; margin: 0 0 0 5px;">${dataCaption}</p>
  </li>`;
    panier.insertAdjacentHTML("beforeend", infos);
};

const fetchCa = () => {

const boxs = document.querySelectorAll('.mybox-ca');
boxs.forEach((box) =>{
    box.addEventListener('click', (event) => {
        if (box.checked) {
            addToBasket(box.dataset.caption, box.dataset.id)
            download2();
            localStorage.selectedIds = state.dataset.ids
        } else {
            const id = box.dataset.id
            document.querySelector(`#info-${id}`).remove()
            const arr = state.dataset.ids.split(',')
            const newArr = arr.filter(e =>  e != box.dataset.id )
            state.dataset.ids = newArr.join(',')
            localStorage.selectedIds = state.dataset.ids
            download2();
        }
    });
 });  
};


 
const download2 = () => {
    const checkIdCa = [];
    const boxs = document.querySelectorAll('.mybox-ca');
    boxs.forEach((box) =>{
        if (box.checked){
            checkIdCa.push(box.dataset.id);    
        }   
    });

    const state = document.querySelector('#state')
    const ids = state.dataset.ids.split(',')
    const result = [...checkIdCa, ...ids]
        
    state.dataset.ids = result.filter((item, index) => result.indexOf(item) === index)
}

export { fetchCas };